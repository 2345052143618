export default {
    init() {
      console.log('single-featured-student');
  
      // image wraps happen in libs/content.js
    },
    finalize() {
      
    },
  };
  