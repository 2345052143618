// Youth (child) theme
// -------------------------

//import jQuery from 'jQuery';
import './child.youth.scss';
import Router from './util/Router';

import common from './routes/child.youth/common';
import projectLibrary from './routes/child.youth/projectLibrary';
import singleFeaturedStudent from './routes/child.youth/singleFeaturedStudent';
import floatingShapes from './routes/child.youth/floatingShapes';

import singlePost from './routes/parent/singlePost';
import singleProject from './routes/parent/singleProject';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  home: floatingShapes,
  singlePost,
  project: singleProject,
  projectLibrary,
  singleFeaturedStudent,
  startAProject: floatingShapes,
  challengesAndTopics: floatingShapes,
  contact: floatingShapes,
  contactUs: floatingShapes,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
