import { ScrollListener } from 'pickles';
import urijs from 'urijs';
import $ from 'jquery';
import 'jquery-smooth-scroll';

export const getProjectTileTemplate = () => $( '[data-project-item]' ).show().detach()

export const getProjectDetailTemplates = (el) => ({
  memberName: el.find( '[data-project-members] [data-project-member]' ).detach(),
  member: el.find( '[data-project-member-biographies] [data-project-member]' ).detach(),
  award: el.find( '[data-project-awards] [data-project-award]' ).detach(),
});

export const loadProjectIntoTemplate = ( el, project ) => {
  const templates = getProjectDetailTemplates(el);
  const uri = new urijs();
  const lastSegment = uri.segment().filter(s => s).pop();
  const projectLink = isNaN(lastSegment) ? ( project.project_id || '' ) : '';

  el.attr( 'data-loading', false );
  el.find( '[data-project-event-name]' ).html( project.event_name || '' );
  el.find( '[data-project-name]' ).html( project.project_title || '' );
  el.find( '[data-project-link]' ).attr( 'href', projectLink );
  el.find( '[data-project-url]' ).attr( 'href', project.external_project_url || '' );
  el.find( '[data-project-challenge]' ).html( project.challenge || '' );
  el.find( '[data-project-category]' ).html( project.category || '' );
  el.find( '[data-project-region]' ).html( project.region_name || '' );
  el.find( '[data-project-city]' ).html( project.city || '' );
  el.find( '[data-project-school]' ).html( project.school || '' );
  el.find( '[data-project-summary]' ).html( project.project_summary || '' );
  if ( project.project_image_url ) {
    el.find( '[data-project-image]' ).attr( 'src', project.project_image_url || '' );
  }

  //$( el ).find( '[data-project-members]' ).empty();
  //$( el ).find( '[data-project-member-biographies]' ).empty();
  //$( el ).find( '[data-project-awards]' ).empty();

  // members

  ( project.users || [] ).forEach( member => {
      const memberNewEl = templates.member.clone();

      memberNewEl.find( '[data-project-member-name]' ).html( member.name || '' );
      memberNewEl.find( '[data-project-member-biography]' ).html( member.biography || '' );
      memberNewEl.appendTo( el.find( '[data-project-member-biographies]' ) );

      const memberNameNewEl = templates.memberName.clone();
      memberNameNewEl.html( member.name || '' );
      memberNameNewEl.appendTo( el.find( '[data-project-members]' ) );

  } );

  el.find('[data-project-member-biographies-section]').toggle(( project.users || [] ).length > 0);

  ( project.awards || [] ).forEach( award => {
      const awardNewEl = templates.award.clone();

      awardNewEl.find( '[data-project-award-name]' ).html( award.title || '' );
      awardNewEl.find( '[data-project-award-medal]' ).html( award.award_level || '' );
      awardNewEl.find( '[data-project-award-sponsor]' ).html( award.sponsor || '' );
      awardNewEl.find( '[data-project-award-value]' ).html( '$' + award.value || '' ).toggle( award.value > 0 );
      awardNewEl.appendTo( el.find( '[data-project-awards]' ) );
  } );

  el.find('[data-project-awards-section]').toggle(( project.awards || [] ).length > 0);
};

export default {
    init () {
        console.log( 'project-library' );

        // API vars

        const portalApiBase = env.portal.apiBaseUrl || false;
        const per_page = 8;
        const lang = $( 'html' ).attr( 'lang' ) || 'en';
        const minDelay = 500;
        const keywordInputDebounce = 200;

        // dom vars

        const modalEl = $( '[data-project-detail]' );
        const tileTemplate = getProjectTileTemplate();
        const loadMore = $( '[data-load-more]' );
        const provinceDropdown = $( ':input[data-province]' );
        const ageGroupDropdown = $( ':input[data-category]' );
        const yearDropdown = $( ':input[data-year]' );
        const keywordInput = $( ':input[data-keyword]' );
        const clearFiltersButton = $( '[data-clear-filters]' );

        // search vars

        let page = 1;
        let challenge = "";
        let province = "";
        let ageGroup = "";
        let keyword = "";
        let totalProjects = 0;
        let debounceTimerId;
        let year = env.theme == 'child-ysc' ? ( $(':not(:input)[data-year]').data('year') || (new Date()).getFullYear() ) : "";
        let loading = true;
        let lastLoadMore = -1;
        let noMoreProjects = false;

        // add event handlers to inputs

        const addEventListeners = () => {
            // province change

            provinceDropdown
                .attr( 'disabled', false )
                .on( 'change', e => {
                    province = $( e.currentTarget ).val();
                    getProjects();
                } );

            // age group change

            ageGroupDropdown
                .attr( 'disabled', false )
                .on( 'change', e => {
                    ageGroup = $( e.currentTarget ).val();
                    getProjects();
                } );

            // year change
            
            yearDropdown
                .attr( 'disabled', false )
                .on( 'change', e => {
                    year = $( e.currentTarget ).val();
                    getProjects();
                } );

            // hide dropdown on YSC site

            if ( env.theme == 'child-ysc' ) {
                yearDropdown.hide();
            }    

            // keyword search

            keywordInput
                .attr( 'disabled', false )
                .on( 'input', e => {
                    clearTimeout( debounceTimerId );
                    debounceTimerId = setTimeout( () => {
                        keyword = $( e.currentTarget ).val();
                        getProjects();
                        debounceTimerId = undefined;
                    }, keywordInputDebounce );
                } );

            // clear filters click

            clearFiltersButton
                .on( 'click', e => {
                    e.preventDefault();
                    resetSearch();
                    getProjects();
                } );

            // challenge click

            $( '[data-challenge]' )
                .on( 'click', ( e ) => {
                    setLoadingState( true );
                    $( '[data-challenge]' ).removeClass( 'active' );
                    challenge = $( e.currentTarget ).data( 'challenge' );
                    $( e.currentTarget ).addClass( 'active' );
                    getProjects();
                    // scroll down to results
                    $.smoothScroll({
                        scrollTarget: '#results',
                        offset: $(window).height() * -0.5,
                    });
                } );

            // scroll listener

            const scrollListener = new ScrollListener( () => {
                const y = $( window ).scrollTop();
                const isOnScreen = loadMore.offset().top - $( window ).innerHeight() < y;
                const scrolledFurther = lastLoadMore < y;

                if ( isOnScreen && !loading && scrolledFurther && !noMoreProjects ) {
                    lastLoadMore = y;
                    getProjects( true );
                }
            } );

        };

        // set the global loading state

        const setLoadingState = state => {
            $( '[data-load-more]' ).attr( 'data-loading', state );
            loading = state;
        };

        // load static data

        const loadStaticData = () => {

            // challenges

            $.getJSON( `${portalApiBase}/challenges?event_year=${year}&lang=${lang}` )
                .then( result => result.data )
                .then( challenges => {
                    challenges.forEach( challenge => {
                        $( `[data-challenge=${challenge.slug}]` ).find( '[data-challenge-name]' ).html( challenge.challenge );
                    } );
                } );

            // categories

            $.getJSON( `${portalApiBase}/categories?event_year=${year}&lang=${lang}` )
                .then( result => result.data )
                .then( categories => {
                    categories.forEach( category => {
                        $( `[data-category]` ).append( `<option value="${category.slug}">${category.category_name}</option>` )
                    } );
                } );

            // provinces

            $.getJSON( `${portalApiBase}/provinces?event_year=${year}&lang=${lang}` )
                .then( result => result.data )
                .then( provinces => {
                    provinces.forEach( province => {
                        $( `[data-province]` ).append( `<option value="${province.id}">${province.province}</option>` )
                    } );
                } );

        };

        //  get project search url based on current vars

        const getProjectSearchUrl = () => {
            return `${portalApiBase}/projects?` + [
                `province=${province}`,
                `challenge=${challenge}`,
                `category=${ageGroup}`,
                `fulltext_keyword=${keyword}`,
                `page=${page}`,
                `per_page=${per_page}`,
                `event_year=${year}`,
                `lang=${lang}`,
            ].join( '&' );
        };

        // reset the UI

        const resetSearch = () => {
            if ( provinceDropdown.length ) {
                province = "";
                provinceDropdown.val( "" );
            }
            if ( ageGroupDropdown.length ) {
                ageGroup = "";
                ageGroupDropdown.val( "" );
            }
            if ( keywordInput.length ) {
                keyword = "";
                keywordInput.val( "" );
            }
            if ( yearDropdown.length ) {
                year = "";
                yearDropdown.val( "" );
            }
            challenge = "";
            $( '[data-challenge]' ).removeClass( 'active' );
            $( '[data-challenge=""]' ).addClass( 'active' );
        };

        // clear loaded projects

        const clearProjects = () => {
            $( '[data-results]' ).empty();
            page = 1;
            totalProjects = 0;
            lastLoadMore = -1;
            noMoreProjects = false;
            $( '[data-total-projects]' ).html( totalProjects.toString() );
        };

        // get new projects

        const getProjects = ( append = false ) => {
            if ( !append ) {
                clearProjects();
            }

            setLoadingState( true );
            const startTime = Date.now();

            $.getJSON( getProjectSearchUrl() )
                .then( response => {
                    totalProjects = response.meta.total;
                    $( '[data-total-projects]' ).html( totalProjects.toString() );
                    noMoreProjects = response.meta.last_page === response.meta.current_page;
                    
                    /*
                    if ( noMoreProjects ) {
                        console.log( 'no more projects' );
                    }
                    */

                    return response.data;
                } )
                .then( addProjectTiles )
                .then( ( ok ) => {
                    const millis = Date.now() - startTime;
                    //console.log( `load complete after ${millis}ms` );

                    setTimeout( () => {
                        setLoadingState( false );
                    }, Math.max( minDelay - millis, 0 ) );

                    if ( ok ) {
                        page++;
                    }
                } );
        };

        // populate the modal when a project is clicked

        const populateModal = async ( data ) => {
            modalEl.attr( 'data-loading', true );
            const uri = new urijs();
            uri.setQuery({ id: data.id });
            window.history.replaceState( {}, '', uri );
            const project = ( await $.getJSON( `${portalApiBase}/project/${data.id}` ) )['data'];

            loadProjectIntoTemplate(modalEl, project);
        };

        // create thumbnail tiles for projects

        const addProjectTiles = ( projects ) => {

            if ( projects.length === 0 ) {
                //console.log( 'no projects found' );
                return false;
            }

            projects.forEach( project => {

                const newEl = tileTemplate.clone();

                $( newEl.find( '[data-project-name]' ) ).html( project.project_title || '' );
                $( newEl.find( '[data-project-year]' ) ).html( project.event_year || '' );
                const memberTemplate = $( newEl.find( '[data-project-member]' ) ).detach();

                ( project.users || [] ).forEach( member => {
                    const memberNewEl = memberTemplate.clone();
                    memberNewEl.html( member || '' );
                    memberNewEl.appendTo( $( newEl.find( '[data-project-members]' ) ) );
                } );

                newEl.on( 'click', e => populateModal( { ...project } ) );
                newEl.appendTo( '[data-results]' );
            } );

            return true;
        };

        // update url as projects are shown

        const initWindowHistory = () => {
            $( document )
                .on( 'modalOpen', e => {
                    //
                } )
                .on( 'modalClose', e => {
                    const uri = new urijs();
                    uri.removeQuery('id');
                    window.history.replaceState( {}, '', uri );
                } );

        };

        const tryRedirect = () => {
            const projectID = urijs.parseQuery(location.search)['id'];
            if ( !projectID ) {
                return false;
            }
            const uri = new urijs();
            uri.removeQuery('id');
            window.history.replaceState( {}, '', uri );
            location.href = urijs.joinPaths(uri, projectID);
            return true;
        }

        const getInitialParameters = () => {
            const params = urijs.parseQuery(location.search);
            if (params.challenge) {
                if ($( `[data-challenge="${params.challenge}"]` ).length) {
                    $( '[data-challenge]' ).removeClass( 'active' );
                    $( `[data-challenge="${params.challenge}"]` ).addClass( 'active' );
                    challenge = params.challenge;
                }
            }
        }

        // go

        const doingRedirect = tryRedirect();

        if ( ! doingRedirect ) {
            getInitialParameters();
            loadStaticData();
            addEventListeners();
            initWindowHistory();
            getProjects();
        }
    },
    finalize () {
        
    },
};
