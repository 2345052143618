import $ from 'jquery';

export default {
    init() {
        
        $('[data-append-to-section]')
          .detach()
          .each(( index, el ) => {
            const section = $(`section:nth-of-type(${ $(el).data('append-to-section') + 1 })`);

            if ( section.length ) {
              $(el).appendTo(section);    
              section.addClass('has-shapes');
            }
            
          });
    }
}

        
