export default {
  init() {
    console.log('single-post');

    // image wraps happen in libs/content.js
  },
  finalize() {
    
  },
};
