import { loadProjectIntoTemplate } from './projectDirectory';

export default {
  init() {
    console.log('single-project');

    const portalApiBase = env.portal.apiBaseUrl || false;
    const projectId = $('[data-project-id]').data('projectId');

    const start = async () => {
      try {
        const project = ( await $.getJSON(`${portalApiBase}/project/${projectId}`) )['data'];
        renderProject( project );
      }
      catch ( e ) {
        console.error( e );
      }
    };
    start();

    const renderProject = (project) => {
      const el = $('[data-project-detail]');
      loadProjectIntoTemplate(el, project);
    }
  },
  finalize() {
    
  },
};
