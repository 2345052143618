import ProjectDirectory from '../parent/projectDirectory';
import FloatingShapes from '../libs/floating-shapes';

export default {
    init () {
        console.log( 'project-library' );

        ProjectDirectory.init();
        FloatingShapes.init();

    },
    finalize () {
        
    },
};
